import { ReadonlyURLSearchParams } from 'next/navigation';
import { isEmpty } from 'radash';
interface IUtmParams {
  campaign: string;
  source: string;
  medium: string;
}
export const getUtmParams = (searchParams: ReadonlyURLSearchParams, defaultParams: IUtmParams): string => {
  const utmCampaign = searchParams.get('utm_campaign');
  const utmSource = searchParams.get('utm_source');
  const utmMedium = searchParams.get('utm_medium');
  const campaign = isEmpty(utmCampaign) ? defaultParams.campaign : utmCampaign;
  const source = isEmpty(utmSource) ? defaultParams.source : utmSource;
  const medium = isEmpty(utmMedium) ? defaultParams.medium : utmMedium;
  return `utm_campaign=${campaign}&utm_source=${source}&utm_medium=${medium}`;
};