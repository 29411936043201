import { FC, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import Image from 'next/image';
import { useSearchParams } from 'next/navigation';
import Cookies from 'js-cookie';
import { isEmpty } from 'radash';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import { Autoplay, EffectFade, Pagination } from 'swiper/modules';
import { RegisterBannerType } from '@modules/libs/graphql/generated/graphql';
import { registerBannerStore } from '@modules/registerBanner/adapter/outbound/stores/registerBanner.store';
import { Content } from '@components/layouts';
import breakpoint from '@shared/assets/breakpoint.json';
import { getAuthCookiesName } from '@shared/helpers/getAuthCookiesName.helper';
import { getUtmParams } from '@shared/helpers/getUtmParams.helper';
import { useWindowSize } from '@shared/utils/hooks';
import { analytics } from '@shared/utils/segment.utils';
import { BannerRegister, Container, ImgBanner, ReflexShadow } from './RegisterBanner.style';
const RegisterBannerLogic: FC = () => {
  const {
    width
  } = useWindowSize();
  const token = Cookies.get(getAuthCookiesName());
  const isDesktop = width > breakpoint.md - 1;
  const searchParams = useSearchParams();
  const defaultUtmParams = {
    campaign: 'CPBrandsite',
    source: 'Website_Direct',
    medium: 'MainNavButton'
  };
  const {
    registerBanner,
    fetchRegisterBanner,
    fetchSocialMediaBanner
  } = registerBannerStore(state => state);
  useEffect(() => {
    if (token) {
      fetchSocialMediaBanner();
    } else {
      fetchRegisterBanner(RegisterBannerType.BannerRegister);
    }
  }, [token]);
  const handleOnClickTracker = (title: string, linkout: string) => {
    analytics.track('Banner Register Click', {
      event: 'banner_register_click',
      banner_name: title
    });
    analytics.track('Offsite Linked', {
      event: 'offsite_linked',
      destination_url: linkout
    });
  };
  return !isEmpty(registerBanner) && <Content>
                <Container>
                    <Swiper effect={'fade'} loop autoplay={{
        delay: 3000
      }} pagination={{
        clickable: true,
        el: '.swiper-pagination',
        renderBullet: (_, className) => {
          return '<div class="' + className + '">' + '</div>';
        }
      }} modules={[Autoplay, EffectFade, Pagination]}>
                        {registerBanner?.map(({
          id,
          title,
          linkOutUrl,
          bannerDesktop,
          bannerMobile
        }) => <SwiperSlide key={id}>
                                    <BannerRegister onClick={() => {
            handleOnClickTracker('banner_register', linkOutUrl);
          }}>
                                        <a href={`${linkOutUrl}?${getUtmParams(searchParams, defaultUtmParams)}`} target="_blank" aria-label="register banner">
                                            <ImgBanner src={isDesktop ? bannerDesktop : bannerMobile} width={1140} height={440} alt={title} className="banner-image" />
                                            <ReflexShadow>
                                                <Image src={isDesktop ? bannerDesktop : bannerMobile} width={1140} height={440} alt={title} />
                                            </ReflexShadow>
                                        </a>
                                    </BannerRegister>
                                </SwiperSlide>)}
                        <div className={`swiper-pagination${registerBanner.length === 1 ? ' disabled' : ''}`} />
                    </Swiper>
                </Container>
            </Content>;
};
export default RegisterBannerLogic;