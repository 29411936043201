import { Builder } from 'builder-pattern';
import { create } from 'zustand';
import { loadingStore } from '@modules/core/adapter/outbound/stores/ui.store';
import { GetTikTokHashtagUsecase } from '@modules/trends/application/usecases/getTikTokHashtag.usecase';
import { ITikTokHashtag } from '@modules/trends/domain/models/tiktok.model';
import { TikTokHashtagRepositoryImp } from '../../inbound/repositories/tiktokHashtag.repository';
interface TikTokHashtagState {
  tiktokHashtag: ITikTokHashtag[];
  fetchTikTokHashtag: () => Promise<void>;
}
export const tiktokHashtagStore = create<TikTokHashtagState>(set => ({
  tiktokHashtag: Builder<ITikTokHashtag[]>().build(),
  fetchTikTokHashtag: async () => {
    try {
      loadingStore.setState({
        isLoading: true
      });
      const tiktokHashtagRepo = new TikTokHashtagRepositoryImp();
      const getTikTokHashtagUsecase = new GetTikTokHashtagUsecase(tiktokHashtagRepo);
      const result = await getTikTokHashtagUsecase.handle();
      if (result.isRight) {
        set(() => ({
          tiktokHashtag: result.value
        }));
      }
    } catch (err) {
      console.error('Error fetchTikTokHashtag', err);
    } finally {
      loadingStore.setState({
        isLoading: false
      });
    }
  }
}));