import { FC, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import Image from 'next/image';
import SwiperCore from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { FreeMode, Mousewheel, Navigation, Pagination, Scrollbar } from 'swiper/modules';
import { INewCollectionList } from '@modules/collections/domain/models/newCollection.model';
import { CollectionCover, NewCollectionLayout } from '@components/collection/newCollection';
import { SeeAll } from '@components/seeAll';
import { Title } from '@components/title';
import breakpoint from '@shared/assets/breakpoint.json';
import { useWindowSize } from '@shared/utils/hooks';
import { analytics } from '@shared/utils/segment.utils';
import { Container, ContentWrapper, CoverContainerMobile, CustomSlideAction, SlideArrow, SlideArrowWrapper, SlideContainer, SlidePagination, SlideScrollbar } from './MonthlyCollection.style';
interface Props {
  data: INewCollectionList[];
}
const MonthlyCollection: FC<Props> = ({
  data
}: Props) => {
  SwiperCore.use([FreeMode, Mousewheel, Navigation, Pagination, Scrollbar]);
  const {
    width
  } = useWindowSize();
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const paginationRef = useRef(null);
  const scrollbarRef = useRef(null);
  const [isSlideEnd, setIsSlideEnd] = useState(false);
  const [isSlideStart, setIsSlideStart] = useState(true);
  const slideArrowMotionVariants = {
    enabled: {
      opacity: 1
    },
    disabled: {
      opacity: 0.4
    }
  };
  const onSlideChange = (swiper: SwiperCore) => {
    setIsSlideStart(swiper.isBeginning);
    setIsSlideEnd(swiper.isEnd);
  };
  const renderCustomFraction = (current: number, total: number): string => {
    return `<span class="page-number">${current}</span>
        <span class="bullet"> • </span>
        <span class="page-number">${total}</span>`;
  };
  const renderMobileLayout = (data: INewCollectionList) => {
    return <CoverContainerMobile>
                <CollectionCover menuAmount={data.menuAmount} name={data.name} imageUrl={data.imageUrl} videoUrl={data.videoUrl} slug={data.slug} recipeList={data.recipeList.thumbS} />
            </CoverContainerMobile>;
  };
  const onClickArrow = () => {
    if (isSlideEnd) {
      analytics.track('Monthly Collection Try Loop', {
        event: 'monthly_collection_try_loop'
      });
    }
  };
  return <Container>
            <ContentWrapper>
                <Title text="เมนูคอลเลกชันประจำเดือน" extra={<SeeAll url="/collection" onClick={() => {
        analytics.track('monthly_collection_see_all_click', {
          event: 'monthly_collection_see_all_click'
        });
      }} />} $mobileFontSize={32} />
                <SlideContainer>
                    <Swiper slidesPerView={1.15} spaceBetween={16} slidesOffsetBefore={16} slidesOffsetAfter={16} breakpoints={{
          [breakpoint.md]: {
            slidesPerView: 1,
            spaceBetween: 34,
            slidesOffsetBefore: 0,
            slidesOffsetAfter: 0
          }
        }} navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current
        }} onBeforeInit={swiper => {
          // @ts-ignore
          // eslint-disable-next-line no-param-reassign
          swiper.params.navigation.prevEl = navigationPrevRef.current;
          // @ts-ignore
          // eslint-disable-next-line no-param-reassign
          swiper.params.navigation.nextEl = navigationNextRef.current;
          // @ts-ignore
          // eslint-disable-next-line no-param-reassign
          swiper.params.pagination.el = paginationRef.current;
          // @ts-ignore
          // eslint-disable-next-line no-param-reassign
          swiper.params.scrollbar.el = scrollbarRef.current;
        }} pagination={{
          el: paginationRef.current,
          type: 'custom',
          renderCustom: (_, current, total) => renderCustomFraction(current, total)
        }} scrollbar={{
          el: scrollbarRef.current
        }} onSlideChange={swiper => onSlideChange(swiper)} mousewheel={{
          forceToAxis: true
        }} freeMode={{
          enabled: width < breakpoint.lg
        }} observer observeParents>
                        {data?.map((collection, index) => <SwiperSlide key={index}>
                                {width > breakpoint.md ? <NewCollectionLayout data={collection} /> : renderMobileLayout(collection)}
                            </SwiperSlide>)}
                    </Swiper>
                    {width > breakpoint.md && <CustomSlideAction>
                            <div>
                                <SlidePagination ref={paginationRef} />
                                <SlideScrollbar ref={scrollbarRef} />
                            </div>
                            <SlideArrowWrapper>
                                <SlideArrow ref={navigationPrevRef} animate={isSlideStart ? 'disabled' : 'enabled'} variants={slideArrowMotionVariants} onClick={onClickArrow}>
                                    <Image src="/icons/arrow-circle-left-bg-white.svg" alt="slide-arrow" width={60} height={71} />
                                </SlideArrow>
                                <SlideArrow ref={navigationNextRef} animate={isSlideEnd ? 'disabled' : 'enabled'} variants={slideArrowMotionVariants} onClick={onClickArrow}>
                                    <Image src="/icons/arrow-circle-right-bg-white.svg" alt="slide-arrow" width={60} height={71} />
                                </SlideArrow>
                            </SlideArrowWrapper>
                        </CustomSlideAction>}
                </SlideContainer>
            </ContentWrapper>
        </Container>;
};
export default MonthlyCollection;