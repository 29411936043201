import { Button } from 'antd';
import { motion } from 'framer-motion';
import styled from 'styled-components';
export const Container = styled.div.withConfig({
  displayName: "MonthlyCollectionstyle__Container",
  componentId: "sc-zp8jce-0"
})(["", ""], {
  "marginBottom": "5rem",
  "marginTop": "60px",
  "display": "flex",
  "flexDirection": "column",
  "alignItems": "center",
  "justifyContent": "center",
  "gap": "2rem"
});
export const ContentWrapper = styled.div.withConfig({
  displayName: "MonthlyCollectionstyle__ContentWrapper",
  componentId: "sc-zp8jce-1"
})(["", " max-width:", ";"], {
  "marginLeft": "auto",
  "marginRight": "auto",
  "width": "100%"
}, ({
  theme
}) => `${theme.layout.width}`);
export const SlideContainer = styled.div.withConfig({
  displayName: "MonthlyCollectionstyle__SlideContainer",
  componentId: "sc-zp8jce-2"
})(["", " @media (max-width:", "){", "}@media (max-width:", "){", "}"], {
  "marginTop": "1.5rem",
  "width": "100%",
  "paddingLeft": "0px",
  "paddingRight": "0px"
}, ({
  theme
}) => theme.breakpoint.xl, {
  "paddingLeft": "1rem",
  "paddingRight": "1rem"
}, ({
  theme
}) => theme.breakpoint.md, {
  "paddingLeft": "0px",
  "paddingRight": "0px"
});
export const CustomSlideAction = styled.div.withConfig({
  displayName: "MonthlyCollectionstyle__CustomSlideAction",
  componentId: "sc-zp8jce-3"
})(["", " grid-template-columns:calc(100% - 158px) 130px;"], {
  "marginTop": "1rem",
  "display": "grid",
  "width": "100%",
  "justifyContent": "space-between",
  "gap": "1.75rem"
});
export const SlidePagination = styled.div.withConfig({
  displayName: "MonthlyCollectionstyle__SlidePagination",
  componentId: "sc-zp8jce-4"
})(["", " .bullet{", "}"], {
  "paddingLeft": "0.5rem",
  "paddingRight": "0.5rem",
  "fontSize": "21px",
  "fontWeight": "400",
  "lineHeight": "1.5rem"
}, {
  "--tw-text-opacity": "1",
  "color": "rgb(206 14 45 / var(--tw-text-opacity, 1))"
});
export const SlideScrollbar = styled.div.withConfig({
  displayName: "MonthlyCollectionstyle__SlideScrollbar",
  componentId: "sc-zp8jce-5"
})(["", " position:unset !important;.swiper-scrollbar-drag{", "}"], {
  "marginTop": "0.375rem",
  "height": "0.25rem",
  "width": "100%",
  "borderRadius": "1rem",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(235 235 235 / var(--tw-bg-opacity, 1))"
}, {
  "borderRadius": "100px",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(43 43 43 / var(--tw-bg-opacity, 1))"
});
export const SlideArrowWrapper = styled.div.withConfig({
  displayName: "MonthlyCollectionstyle__SlideArrowWrapper",
  componentId: "sc-zp8jce-6"
})(["", ""], {
  "display": "flex",
  "justifyContent": "space-between",
  "gap": "0.625rem"
});
export const SlideArrow = styled(motion.div).withConfig({
  displayName: "MonthlyCollectionstyle__SlideArrow",
  componentId: "sc-zp8jce-7"
})(["", ""], {
  "cursor": "pointer"
});
export const SeeAllButton = styled(Button).withConfig({
  displayName: "MonthlyCollectionstyle__SeeAllButton",
  componentId: "sc-zp8jce-8"
})(["", ""], {
  "height": "42px",
  "borderRadius": "9999px",
  "borderWidth": "1px",
  "--tw-border-opacity": "1",
  "borderColor": "rgb(206 206 206 / var(--tw-border-opacity, 1))",
  "paddingLeft": "1.5rem",
  "paddingRight": "1.5rem",
  "paddingTop": "0.375rem",
  "paddingBottom": "0.375rem",
  "fontSize": "28px",
  "fontWeight": "500",
  "lineHeight": "30px",
  "--tw-text-opacity": "1",
  "color": "rgb(88 88 88 / var(--tw-text-opacity, 1))"
});
export const CoverContainerMobile = styled.div.withConfig({
  displayName: "MonthlyCollectionstyle__CoverContainerMobile",
  componentId: "sc-zp8jce-9"
})(["", ""], {
  "height": "370px"
});