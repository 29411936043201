import Image from 'next/image';
import styled from 'styled-components';
export const Container = styled.div.withConfig({
  displayName: "RegisterBannerstyle__Container",
  componentId: "sc-1bwffmr-0"
})(["", " .swiper{", "}.swiper-pagination{", " &.disabled{", "}}.swiper-pagination-bullet{", "}.swiper-pagination-bullet-active{", " background:linear-gradient(90deg,#ce0e2d 0%,#df2b41 100%);}"], {
  "marginTop": "5rem"
}, {
  "overflow": "visible"
}, {
  "position": "absolute",
  "bottom": "-4rem",
  "left": "50%",
  "width": "auto",
  "--tw-translate-x": "-50%",
  "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
  "--tw-translate-y": "-50%"
}, {
  "display": "none"
}, {
  "height": "0.625rem",
  "width": "0.625rem",
  "borderRadius": "9999px",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(206 206 206 / var(--tw-bg-opacity, 1))",
  "opacity": "1",
  "transitionProperty": "all",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "transitionDuration": "150ms"
}, {
  "width": "30px"
});
export const BannerRegister = styled.div.withConfig({
  displayName: "RegisterBannerstyle__BannerRegister",
  componentId: "sc-1bwffmr-1"
})(["", " &:hover > a > div{", " -webkit-filter:blur(30px);filter:blur(30px);transition:all 0.2s ease-in-out;}"], {
  "position": "relative"
}, {
  "top": "25px",
  "opacity": "0.8"
});
export const ImgBanner = styled(Image).withConfig({
  displayName: "RegisterBannerstyle__ImgBanner",
  componentId: "sc-1bwffmr-2"
})(["", " @media (max-width:", "){", "}"], {
  "position": "relative",
  "zIndex": "10",
  "height": "auto",
  "width": "100%",
  "borderRadius": "20px"
}, ({
  theme
}) => theme.breakpoint.md, {
  "borderRadius": "1rem"
});
export const ReflexShadow = styled.div.withConfig({
  displayName: "RegisterBannerstyle__ReflexShadow",
  componentId: "sc-1bwffmr-3"
})(["", " -webkit-filter:blur(0px);filter:blur(0px);transition:all 0.2s ease-in-out;@media (max-width:", "){", "}"], {
  "position": "absolute",
  "left": "0px",
  "right": "0px",
  "top": "0px",
  "zIndex": "0",
  "borderRadius": "20px",
  "opacity": "0"
}, ({
  theme
}) => theme.breakpoint.md, {
  "display": "none"
});