import { GetRegisterBannerQueryDocument, GetSocialMediaBannerDocument, RegisterBannerResponse, RegisterBannerType } from '@modules/libs/graphql/generated/graphql';
import { gqlQuery } from '@modules/libs/graphql/gql-config';
import { IRegisterBannerRepository } from '@modules/registerBanner/application/ports/registerBanner.repository.port';
import { IRegisterBanner } from '@modules/registerBanner/domain/models/registerBanner.model';
import { RegisterBannerEntityMapper } from '../../outbound/ports/registerBanner.mapper';
export class RegisterBannerRepositoryImpl implements IRegisterBannerRepository {
  async getRegisterBanner(registeredType: RegisterBannerType): IRegisterBannerRepository.getBannerOutput {
    try {
      const {
        data,
        errors
      } = await gqlQuery({
        query: GetRegisterBannerQueryDocument,
        variables: {
          where: {
            registerBannerType: registeredType
          }
        }
      });
      if (errors) throw errors[0].message ?? 'Unknow Error';
      const output: IRegisterBanner[] = [RegisterBannerEntityMapper.toDomain(data.getRegisterBanner)];
      return output;
    } catch (err) {
      console.error('RegisterBannerRepositoryImpl.getRegisterBanner', err);
      throw {
        data: err,
        message: err.message,
        statusCode: err.graphQLErrors[0].extensions.code
      };
    }
  }
  async getSocialMediaBanner(): IRegisterBannerRepository.getBannerOutput {
    try {
      const {
        data,
        errors
      } = await gqlQuery({
        query: GetSocialMediaBannerDocument
      });
      if (errors) throw errors[0].message ?? 'Unknow Error';
      const output = data?.getRegisterBannerList?.map((item: RegisterBannerResponse) => RegisterBannerEntityMapper.toDomain(item));
      return output;
    } catch (err) {
      console.error('RegisterBannerRepositoryImpl.getSocialMediaBanner', err);
      throw {
        data: err,
        message: err.message,
        statusCode: err.graphQLErrors[0].extensions.code
      };
    }
  }
}